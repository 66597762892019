<template>
  <el-dialog title="Datos de registro" width="80%" :visible.sync="openModal" :close-on-click-modal="false"
    :close-on-press-escape="false" :before-close="closeModal" :destroy-on-close="true" top="2vh">
    <el-form :model="form" :rules="rules" ref="form" status-icon label-position="top" label-width="150px" size="medium"
      class="form" v-loading="loadingForm">
      <el-row :gutter="20">
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Categoria" prop="category_id">
            <el-select v-model="form.category_id" filterable clearable placeholder="Selecciona"
              @change="SetCodeCategory">
              <el-option v-for="item in categories" :key="item.id" :label="item.descripcion" :value="item.id">
                <span style="float: left"><i class="el-icon-collection-tag"></i>
                  {{ item.descripcion }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Código" prop="code">
            <label slot="label">
              <el-tooltip class="item" effect="dark" content="Código que le asigna el proveedor" placement="top-start">
                <i class="el-icon-warning-outline color_info btn_info"></i>
              </el-tooltip>
              Código</label>
            <el-input type="text" v-model="form.code" autocomplete="off" placeholder="Código" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Código Interno" prop="code_int">
            <el-input type="text" v-model="form.code_int" autocomplete="off" placeholder="Código Interno" clearable>
              <template slot="prepend">{{ codeCategorie }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <el-form-item label="Descripción" prop="description">
            <el-input type="text" v-model="form.description" autocomplete="off" placeholder="Descripción" clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Costo" prop="cost">
            <el-input type="number" v-model="form.cost" autocomplete="off" placeholder="$ 0.00" clearable
              @change="calculateProfitPercentageValue"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item prop="profit_percentage">
            <label slot="label">
              <el-tooltip class="item" effect="dark" content="Se calculará la ganancia deacuerdo al costo"
                placement="top-start">
                <i class="el-icon-warning-outline color_info btn_info"></i>
              </el-tooltip>
              % de ganancia
            </label>
            <el-input type="number" v-model="form.profit_percentage" autocomplete="off" placeholder="0%"
              @change="calculateProfitPercentageValue"></el-input>
            <el-alert :title="
              'Ganancia: $' + formatNumber(form.profit_percentage_value)
            " type="info" show-icon :closable="false">
            </el-alert>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Precio de venta" prop="price_sale">
            <el-input type="number" v-model="form.price_sale" autocomplete="off" placeholder="$ 0.00" clearable
              @change="calculateSalePriceFinal(form.tax_id)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="% IVA" prop="tax_id">
            <el-select v-model="form.tax_id" filterable clearable placeholder="Selecciona"
              @change="calculateSalePriceFinal">
              <el-option v-for="item in taxs" :key="item.id" :label="item.valor" :value="item.id">
                <span style="float: left"><i class="el-icon-collection-tag"></i>
                  {{ item.descripcion }} ({{ item.valor }}%)</span>
              </el-option>
            </el-select>
            <el-alert :title="'Precio venta final: $' + formatNumber(salePriceFinal)" type="success" show-icon
              :closable="false">
            </el-alert>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item prop="minimum_stock">
            <label slot="label">
              <el-tooltip class="item" effect="dark" content="El sistema avisará cuando alcance este valor"
                placement="top-start">
                <i class="el-icon-warning-outline color_info btn_info"></i>
              </el-tooltip>
              Stock Minimo
            </label>
            <el-input type="number" v-model="form.minimum_stock" autocomplete="off" placeholder="$ 0.00" clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item prop="discount_percentage">
            <label slot="label">
              <el-tooltip class="item" effect="dark" content="Descuento para aplicar al producto en la facturación"
                placement="top-start">
                <i class="el-icon-warning-outline color_info btn_info"></i>
              </el-tooltip>
              % de descuento
            </label>
            <el-input type="number" v-model="form.discount_percentage" autocomplete="off" placeholder="0%" clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item prop="image" label="Subir Imagen">
            <el-upload class="upload-demo" ref="upload" accept="image/*" :limit="1" :action="baseURLImage"
              :on-exceed="handleExceed" :file-list="fileList" :auto-upload="false" :on-change="handleChange"
              :on-remove="handleRemove">
              <el-button size="small" type="primary">Clic para subir archivo</el-button>
              <div slot="tip" class="el-upload__tip">Tamaño menor de 1MB</div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-form-item prop="product_class" label="Clase de producto">
        <el-switch v-model="form.product_class" active-text="Terminado" inactive-text="Simple">
        </el-switch>
      </el-form-item> -->

      <!-- table pivot -->
      <div class="talbe-pivot" v-if="form.product_class">
        <el-table size="samll" :data="form.productSimple" style="width: 100%" v-loading="loadingTablePivot">
          <el-table-column label="Imagen" width="80">
            <template slot-scope="scope">
              <div class="image-input">
                <el-image v-if="scope.row.product_image" class="avatar" alt="image" style="width: 50px; height: 50px"
                  :src="urlImage + scope.row.product_image" :preview-src-list="[urlImage + scope.row.product_image]">
                </el-image>
                <el-image v-else style="width: 50px; height: 50px" src="/static/images/products/no-image.png"
                  class="avatar" alt="image"></el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="product_simple_id" label="Producto" min-width="200">
            <template slot-scope="scope">
              <el-autocomplete size="small" v-model="scope.row.product_name" :fetch-suggestions="querySearchAsync"
                placeholder="Buscar producto" @select="handleSelect(scope.$index, $event)">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <template slot-scope="{ item }">
                  <div class style="line-height: 1; padding: 8px 0 8px">
                    <i class="mdi mdi-cart-outline"></i>
                    {{ item.descripcion }}
                    <br />
                    <small>
                      <i class="mdi mdi-barcode"></i>
                      {{ item.codigo_interno }}
                    </small>
                    <small>
                      <i class="mdi mdi-tag-text-outline"></i>
                      {{ item.category.descripcion }}
                    </small>
                  </div>
                </template>
              </el-autocomplete>
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="Costo" min-width="100">
            <template slot-scope="scope">
              <el-input size="small" v-model="scope.row.product_cost" :disabled="true">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="Cantidad" min-width="100">
            <template slot-scope="scope">
              <el-input-number size="small" controls-position="right" placeholder="Cantidad"
                v-model="scope.row.quantity" :min="1">
              </el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="product_unit_measurement" label="Unidad Medida" min-width="120">
            <template slot-scope="scope">
              <el-input size="small" placeholder="Unidad de medida" v-model="scope.row.product_unit_measurement"
                :disabled="true">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column width="60">
            <template slot-scope="scope">
              <div class="btn-actions">
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-popconfirm title="Eliminar este registro?" @confirm="deleteRow(scope.$index, scope.row)">
                    <el-button size="mini" icon="el-icon-delete" type="danger" slot="reference" circle>
                    </el-button>
                  </el-popconfirm>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="add-row">
          <el-link @click="addRowClass"><i class="el-icon-plus"></i> Agregar nueva fila</el-link>
        </div>
      </div>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeModal" icon="el-icon-close">Cancelar</el-button>
      <!-- save and edit btn  -->
      <el-button size="small" type="success" @click="submitForm('form')" :loading="loading" icon="el-icon-check"
        v-if="!edit">Guardar</el-button>
      <el-button size="small" type="warning" @click="submitForm('form')" :loading="loading" icon="el-icon-edit" v-else>
        Actualizar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatInt, formatNumber } from "@/utils/number";
import { getAll as getCategories } from "@/api/product/category.js";
//   import { getAll as getUnitMeasurements } from "@/api/administration/unitMeasurement.js";
import {
  getAllTax,
  store,
  update,
  validateCodeExist,
  searchProductByData,
  // deleteProductPivotById,
} from "@/api/product";
export default {
  name: 'ModalFormProduct',
  props: ['openModal', 'dataForm', 'edit'],
  watch: {
    dataForm: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.handleEdit(val);
        } else {
          this.resetForm('form')
        }
      },
    },
  },
  data() {
    var validateIntCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Ingresa un código interno"));
      } else {
        if (this.edit) {
          callback();
        } else {

          validateCodeExist(value)
            .then(({ data }) => {
              console.log("datos", data);
              if (data) {
                callback(new Error("El código interno ingresado, ya existe"));
              } else {
                callback();
              }
            })
            .catch(function (error) {
              console.log(error);
              callback(new Error("Ocurrio un error al validar el código"));
            });
        }
      }
    };
    return {
      baseURLImage: process.env.VUE_APP_ROOT_API + "/product/uploadImage",
      // image
      urlImage: process.env.VUE_APP_ROOT + "static/images/uploads/",
      form: {
        category_id: "",
        unit_measurement_id: "",
        tax_id: 2,
        code: "",
        code_int: "",
        description: "",
        cost: "",
        price_sale: "",
        image_url: "",
        minimum_stock: 0,
        profit_percentage: 0,
        profit_percentage_value: 0,
        discount_percentage: "",
        product_class: false,
        productSimple: [{
          product_simple_id: '',
          product_name: '',
          quantity: 1,
          product_cost: 0,
          product_image: '',
          product_unit_measurement: '',
        }]
      },
      rules: {
        description: [
          {
            required: true,
            message: "Ingresa una descripción",
            trigger: "blur",
          },
        ],
        code_int: [
          {
            validator: validateIntCode,
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "Selecciona una categoria",
            trigger: ["blur", "change"],
          },
        ],
        // unit_measurement_id: [
        //   {
        //     required: true,
        //     message: "Selecciona una unidad de medida",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        tax_id: [
          {
            required: true,
            message: "Selecciona un impuesto",
            trigger: ["blur", "change"],
          },
        ],
        cost: [
          {
            required: true,
            message: "Ingresa el valor de costo",
            trigger: "blur",
          },
        ],
        price_sale: [
          {
            required: true,
            message: "Ingresa el valor de precio venta",
            trigger: "blur",
          },
        ],
        minimum_stock: [
          {
            required: true,
            message: "Ingresa un stock minimo",
            trigger: "blur",
          },
        ],
        // profit_percentage: [
        //   {
        //     required: true,
        //     message: "Este campo no puede ir vacio",
        //     trigger: "blur",
        //   },
        // ],
        // profit_percentage_value: [
        //   {
        //     required: true,
        //     message: "Este campo no puede ir vacio",
        //     trigger: "blur",
        //   },
        // ],
        // discount_percentage: [
        //   {
        //     required: true,
        //     message: "Este campo no puede ir vacio",
        //     trigger: "blur",
        //   },
        // ],
      },
      loadingForm: false,
      loading: false,
      loadingTablePivot: false,
      fileList: [],
      categories: [],
      unitMeasurements: [],
      taxs: [],
      salePriceFinal: 0,
      codeCategorie: "",
      codeCategory: "",
      iconValidateCode: "el-icon-loading",
    }
  },
  methods: {
    handleEdit(row) {
      console.log('edit', row);
      let me = this
      this.form.id = row.id;
      this.form.category_id = parseInt(row.categoria_id);
      this.form.unit_measurement_id = parseInt(row.unidad_medida_id);
      this.form.tax_id = parseInt(row.iva_id);
      this.form.code = row.codigo;
      this.form.code_int = row.codigo_interno;
      this.form.description = row.descripcion;
      this.form.cost = row.costo;
      this.form.price_sale = row.precio_venta;
      this.form.minimum_stock = row.stock_minimo;
      this.form.profit_percentage_value = row.profit_percentage_value;
      this.form.profit_percentage = row.profit_percentage;
      this.form.discount_percentage = row.discount_percentage;
      // product simple
      this.form.product_class = false;
      me.form.productSimple = []
      if (row.product_pivot && row.product_pivot.length > 0) {
        this.form.product_class = true;
        row.product_pivot.forEach(el => {
          let productSimple = {};
          productSimple.id = el.id
          productSimple.product_id = el.product_id
          productSimple.product_simple_id = el.product_simple_id
          productSimple.quantity = el.quantity
          productSimple.product_name = el.product.descripcion
          productSimple.product_cost = '$' + this.formatNumber(el.product.cost)
          productSimple.product_image = el.product.image
          productSimple.product_unit_measurement = el.product.unit_measurement.abbreviation
          me.form.productSimple.push(productSimple)
        });
      }

      me.SetCodeCategory(parseInt(row.categoria_id));
      me.calculateSalePriceFinal(me.form.tax_id);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.codeCategorie = null;
      this.fileList = [];
      this.form.profit_percentage_value = 0;
      this.form.productSimple = [{
        product_simple_id: '',
        product_name: '',
        quantity: 1,
        product_cost: 0,
        product_image: '',
        product_unit_measurement: '',
      }];
      this.salePriceFinal = 0;
    },
    submitForm(formName) {
      this.loading = true;
      this.loadingForm = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loadingForm = false;
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      this.baseURLImage = process.env.VUE_APP_ROOT_API + "/product/uploadImage";
      store(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.baseURLImage = this.baseURLImage + "/" + data.data.id;
            setTimeout(() => {
              me.submitUpload();
            }, 300);

            setTimeout(() => {
              this.closeModal(true);
            }, 2000);
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      let me = this;
      this.baseURLImage = process.env.VUE_APP_ROOT_API + "/product/uploadImage";
      update(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.baseURLImage = this.baseURLImage + "/" + data.datos.id;
            setTimeout(() => {
              me.submitUpload();
            }, 300);
            setTimeout(() => {
              this.closeModal(true);
            }, 2000);
          } else {
            this.$message.error("Error! " + data.error);
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
          me.loading = false;
        });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `El límite es 1 archivo, haz cargado ${files.length} archivo esta vez`
      );
    },
    calculateProfitPercentageValue() {
      let me = this;
      this.form.price_sale = Math.round(
        parseFloat(this.form.cost) / (1 - this.form.profit_percentage / 100)
      );

      this.form.profit_percentage_value = Math.round(
        this.form.price_sale - this.form.cost
      );

      setTimeout(() => {
        me.calculateSalePriceFinal(this.form.tax_id);
      }, 300);
    },
    calculateSalePriceFinal(data) {
      let valueTax = this.taxs.find(tax => tax.id === data);
      let tax = 1;
      if (this.form.tax_id == 1) {
        tax = parseFloat(valueTax.valor) / 100 + 1;
      }
      this.salePriceFinal = Math.round(this.form.price_sale * tax);
    },
    handleSelect(index, event) {
      console.log(event);
      this.form.productSimple[index].product_simple_id = event.id;
      this.form.productSimple[index].product_name = event.descripcion;
      this.form.productSimple[index].product_cost = '$' + this.formatNumber(event.costo);
      this.form.productSimple[index].product_image = event.image;
      this.form.productSimple[index].product_unit_measurement = event.unit_measurement.abbreviation;
      console.log('form', this.form.cost);
      // this.form.cost = parseFloat(this.form.cost) + parseFloat(event.costo);
    },
    clearSelect(index, event) {
      console.log(index, event);
      this.form.productSimple[0].product_simple_id = "";
      this.form.productSimple[0].product_name = "";
      this.form.productSimple[0].product_cost = 0;
      this.form.productSimple[0].product_image = '';
      this.form.productSimple[0].product_unit_measurement = '';
    },
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length > 2) {
        searchProductByData({ data: queryString })
          .then(({ data }) => {
            cb(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteRow(index, row) {
      let me = this
      // if (this.form.productSimple.length === 1) {
      //   return this.$message.warning(
      //     `No es posible eliminar cuando hay un solo item`
      //   );
      // }
      if (typeof row.id !== 'undefined') {
        me.loadingTablePivot = true
        // deleteProductPivotById(row.id)
        //   .then(({ data }) => {
        //     if (data.code === 200) {
        //       me.form.productSimple.splice(index, 1);
        //     } else {
        //       me.$message.warning(
        //         `Ha ocurrido un error al eliminar`
        //       );
        //     }
        //     me.loadingTablePivot = false
        //   })
        //   .catch(function (error) {
        //     me.loadingTablePivot = false
        //     me.$message.warning(
        //       `Error: ${error}`
        //     );
        //     console.log(error);
        //   });
      } else {
        this.form.productSimple.splice(index, 1);
      }
    },
    SetCodeCategory(data) {
      let d = this.categories.filter(categorie => categorie.id === data);
      this.codeCategorie = d[0].code;
    },
    addRowClass() {
      this.form.productSimple.push({
        product_simple_id: '',
        product_name: '',
        quantity: 1,
        product_cost: 0,
        product_image: '',
        product_unit_measurement: '',
      })
    },
    getAllTax() {
      getAllTax()
        .then(({ data }) => {
          this.taxs = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCategories() {
      getCategories()
        .then(({ data }) => {
          this.categories = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUnitsMeasurements() {
      // getUnitMeasurements()
      //   .then(({ data }) => {
      //     this.unitMeasurements = data;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    // image
    removeImage() {
      let me = this;
      this.fileList = [];
    },
    limitImage(files, fileList) {
      this.$message({
        type: "warning",
        message:
          "Debes borrar la imagen seleccionada para poder cargar una nueva.",
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    successImage(response, file, fileList) {
      this.fileList = [];
    },
    closeModal(refreshTable) {
      this.resetForm('form');
      this.loadingForm = false;
      this.loading = false;
      let dataEmit = null
      if (refreshTable === true) {
        dataEmit = { refreshTable: true };
      }
      this.$emit('closeModal', dataEmit);
    }
  },
  created() {
    this.getCategories();
    this.getAllTax();
    this.getUnitsMeasurements();
  },
}
</script>

<style lang="scss" scope>
.el-autocomplete {
  width: 100%;
}
</style>