<template>
  <div>
    <el-dialog
      width="70%"
      height="500"
      top="50px"
      :visible.sync="modalListOpen"
      :before-close="modalListClose"
      :destroy-on-close="true"
    >
      <div slot="title"><i class="mdi mdi-file-search-outline"></i> Devoluciones</div>
      <el-card class="card-shadow--small">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select
              v-model="type_id"
              size="mini"
              multiple
              clearable
              placeholder="Tipo Documento"
              @clear="type_id = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-option
                v-for="item in types"
                :key="item.id"
                :label="item.descripcion"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-input
              placeholder="# Nota Credito"
              size="mini"
              v-model="consecutive"
              clearable
              @clear="consecutive = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="3">
            <el-input
              placeholder="# factura"
              size="mini"
              v-model="documentConsecutive"
              clearable
              @clear="documentConsecutive = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-date-picker
              v-model="date"
              type="date"
              size="mini"
              placeholder="Fecha"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              @clear="date = null"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="client"
              filterable
              remote
              clearable
              reserve-keyword
              size="mini"
              placeholder="Buscar Cliente"
              :remote-method="remoteMethod"
              :loading="loadingClient"
              @clear="client = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
                <span style="float: left">{{ item.nombre }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  ><i class="el-icon-postcard"></i> {{ item.documento }}</span
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              :loading="loadingFilter"
              @click="filterTable"
              >Buscar</el-button
            >
          </el-col>
        </el-row>
      </el-card>
      <!-- TABLE DATA -->
      <el-table :data="tableData" v-loading="loading" height="400" style="width: 100%">
        <el-table-column prop="consecutivo" label="Consecutivo" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.consecutivo }} </span>
            <el-tag v-if="scope.row.estatus == '3'" size="mini" type="danger">Anulado</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="type.descripcion"
          label="Tipo Documento"
          width="130"
        ></el-table-column>
        <el-table-column prop="fecha" label="Fecha" width="110"></el-table-column>
        <el-table-column prop="person.nombre" label="Cliente"></el-table-column>
        <el-table-column prop="branch.razon_social" label="Sucursal"></el-table-column>
        <el-table-column prop="return_money_selected" label="Devolución de dinero" width="100">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.return_money_selected > 0">SI</el-tag>
            <el-tag type="info" v-else>NO</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="subtotal" label="Total" width="100">
          <template slot-scope="scope">
            <span>$ {{ calculateTotal(scope.row) }} </span>
          </template>
        </el-table-column>

        <el-table-column label="Acciones" width="100" class="align-vertical-middle">
          <template slot-scope="scope">
            <div v-if="scope.row.estatus != '3'">
              <el-tooltip class="btn-print" effect="dark" content="Imprimir" placement="top">
                <el-button size="mini" circle @click="printDocument(scope.$index, scope.row)">
                  <i class="el-icon-printer"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                <el-popconfirm
                  confirmButtonText="Si"
                  cancelButtonText="No, Cancelar"
                  icon="el-icon-info"
                  iconColor="red"
                  title="Deseas eliminar este registro?"
                  @confirm="deleteRecord(scope.row)"
                >
                  <el-button slot="reference" size="mini" v-can="'admin'" type="danger" circle>
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-popconfirm>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="tableData.length > 0"
        class="pagination-box"
        style="text-align: end; padding-top: 10px"
      >
        <el-pagination
          layout="total, prev, pager, next"
          :page-size="pageSize"
          :total="total_rows"
          @current-change="handleCurrentPage"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" icon="el-icon-close" @click="modalListClose">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser } from "@/utils/auth";
import { getDocuments, anulateDocument } from "@/api/document";
import { getTypesSelected } from "@/api/type_document";
import { search as searchClient } from "@/api/person";
import { roundToTheNearestAnything, formatNumber, formatInt } from "@/utils/number";
export default {
  name: "ModalList",
  props: ["modalListOpen"],
  watch: {
    modalListOpen(val) {
      if (val) {
        // this.getData();
      }
    },
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      loadingFilter: false,
      loadingClient: false,
      table: null,
      tableData: [],
      types: [],
      options: [],
      // Filters
      lastFilters: null,
      consecutive: null,
      documentConsecutive: null,
      type_id: null,
      date: null,
      client: null,
      // Pagination
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    printDocument(idx, row) {
      this.$emit("print", row.id);
    },
    modalListClose() {
      this.currentPage = 1;
      this.tableData = [];
      this.$emit("close");
    },
    filterTable() {
      this.loadingFilter = true;
      this.getData();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      // Normalizar el filtro de tipo
      this.type_id = this.type_id && this.type_id.length > 0 ? this.type_id : null;

      // Activar estado de carga
      this.loading = true;

      // Solo resetear a página 1 cuando se aplican o cambian filtros,
      // no cuando se cambia de página explícitamente
      const isFilterChanged = this.isFilterChanged();

      if (isFilterChanged) {
        this.currentPage = 1;
      }

      // Guardar los filtros actuales para futuras comparaciones
      this.saveCurrentFilters();

      // Llamar a la API con los parámetros actualizados
      getDocuments(
        this.pageSize,
        this.currentPage,
        this.type_id,
        this.consecutive,
        this.date,
        this.client,
        this.documentConsecutive
      )
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loading = false;
          this.loadingFilter = false;
        })
        .catch((error) => {
          // Usar arrow function para mantener el contexto 'this'
          this.loadingFilter = false;
          this.loading = false;
          console.error("Error al cargar los documentos:", error);
        });
    },

    // Método auxiliar para comparar si los filtros han cambiado
    isFilterChanged() {
      return (
        this.lastFilters?.consecutive !== this.consecutive ||
        this.lastFilters?.documentConsecutive !== this.documentConsecutive ||
        this.lastFilters?.date !== this.date ||
        this.lastFilters?.client !== this.client ||
        this.lastFilters?.type_id !== this.type_id
      );
    },

    // Método para guardar el estado actual de los filtros
    saveCurrentFilters() {
      this.lastFilters = {
        consecutive: this.consecutive,
        documentConsecutive: this.documentConsecutive,
        date: this.date,
        client: this.client,
        type_id: this.type_id,
      };
    },
    getTypes() {
      getTypesSelected({ data: [5, 10] })
        .then(({ data }) => {
          this.types = data;
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
        });
    },
    deleteRecord(row) {
      this.$prompt("Ingres el concepto de la anulación", "Concepto", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        inputValidator: (val) => {
          return val ? true : false;
        },
        inputErrorMessage: "Ingrese un concepto de anulación",
      })
        .then(({ value }) => {
          this.anulateDocument(row.id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Anulación Cancelada",
          });
        });
    },
    anulateDocument(id, concept) {
      this.loading = true;
      anulateDocument({ id_document: id, concept: concept })
        .then(({ data }) => {
          console.log("resp", data);
          this.getData();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loadingClient = true;
        searchClient(query, "cliente")
          .then(({ data }) => {
            this.loadingClient = false;
            this.options = data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options = [];
      }
    },
    calculateTotal(row) {
      var subtotal = formatInt(row.subtotal) + formatInt(row.total_iva);
      var total = subtotal - formatInt(row.descuento_valor);
      return formatNumber(roundToTheNearestAnything(total));
    },
  },
  mounted() {
    this.getTypes();
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.btn-print {
  margin-right: 10px;
}
</style>
