<template>
  <div class="step1">
    <div class="content">
      <el-row :gutter="12">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-card class="box-card list scrollable" shadow="never">
            <el-form
              v-loading="loading_card"
              :model="form"
              :rules="rules"
              :label-position="'top'"
              size="small"
              ref="ruleForm"
              label-width="120px"
              class="demo-ruleForm"
            >
              <el-row :gutter="12">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item prop="nit">
                    <el-input
                      placeholder="Documento cliente"
                      ref="document"
                      v-model="form.nit"
                      clearable
                      suffix-icon="el-icon-postcard"
                      @clear="resetValues"
                      @keyup.native.enter="searchInvoices"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item prop="invoice">
                    <el-input
                      placeholder="# Factura"
                      v-model="form.invoice"
                      clearable
                      suffix-icon="el-icon-document"
                      @clear="resetValues"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="banch">
                <el-select
                  v-model="form.branch"
                  clearable
                  filterable
                  placeholder="Selecciona la tienda"
                >
                  <el-option
                    v-for="item in branchs"
                    :key="item.id"
                    :label="item.razon_social"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="banch" v-if="return_money">
                <el-alert title="Seleccionar la forma en la que será devuelto el dinero" type="warning" show-icon style="margin-bottom: 10px;font-size: 16px;">
                </el-alert>
                <el-select
                  v-model="form.payment"
                  clearable
                  filterable
                  placeholder="Selecciona una forma de pago"
                  ref="payment_selected"
                  @change="setDataDocument"
                >
                  <el-option
                    v-for="item in payments"
                    :key="item.id"
                    :label="item.descripcion"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn-search">
              <el-button
                type="success"
                icon="el-icon-search"
                size="small"
                :loading="loading_search"
                @click="searchInvoices"
                >Buscar</el-button
              >
            </div>
            <div class="observation">
              <el-input
                type="textarea"
                ref="observation"
                :rows="2"
                placeholder="Ingresa una Observación"
                v-model="form.observation"
              >
              </el-input>
            </div>
          </el-card>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="16"
          :lg="16"
          :xl="16"
          class="tables-load"
        >
          <el-card
            class="box-card"
            :body-style="{ padding: '5px' }"
            v-loading="loading_card"
          >
            <div slot="header" class="clearfix">
              <span v-if="show_invoice_detail"
                >Elige los productos de la factura
                <strong
                  >#{{ form.invoice }} -
                  {{ document.branch.razon_social }}</strong
                ></span
              >
              <span v-if="show_invoice">Selecciona alguna factura</span>
            </div>
            <!-- TABLA DE DETALLES DE FACTURA -->
            <el-table
              :data="tableDetail"
              height="250"
              size="small"
              style="width: 100%"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
              v-show="show_invoice_detail"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="code" label="Código" width="150">
              </el-table-column>
              <el-table-column prop="description" label="Descripción">
              </el-table-column>
              <el-table-column prop="quantity" label="Cantidad" width="80">
              </el-table-column>
              <el-table-column prop="price" label="Precio" width="100">
                <template slot-scope="scope">
                  $ {{ formatNumber(scope.row.price) }}
                </template>
              </el-table-column>
              <el-table-column prop="iva" label="Iva" width="90">
                <template slot-scope="scope">
                  $ {{ formatNumber(scope.row.iva) }}
                </template>
              </el-table-column>
              <el-table-column prop="descuento" label="Descuento" width="90">
                <template slot-scope="scope">
                  - $ {{ formatNumber(scope.row.descuento) }}
                </template>
              </el-table-column>
              <el-table-column prop="total" label="Total" width="100">
                <template slot-scope="scope">
                  $ {{ formatNumber(scope.row.total) }}
                </template>
              </el-table-column>
            </el-table>
            <!-- TABLA DE FACTURAS -->
            <el-table
              :data="
                tableInvoice.filter(
                  (data) =>
                    !search ||
                    data.date.toLowerCase().includes(search.toLowerCase())
                )
              "
              height="250"
              size="small"
              style="width: 100%"
              v-show="show_invoice"
            >
              <el-table-column
                prop="consecutive"
                label="Consecutivo"
                width="200"
              >
                <template slot-scope="scope">
                  <div>{{ scope.row.consecutive }}</div>
                  <small>{{ scope.row.type.descripcion }}</small>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="Fecha" width="110">
              </el-table-column>
              <el-table-column prop="branch.razon_social" label="Sucursal">
              </el-table-column>
              <el-table-column prop="name" label="Cliente" min-width="180">
              </el-table-column>
              <!-- <el-table-column prop="value" label="Valor" width="100"> -->
              <!-- </el-table-column> -->
              <el-table-column align="right" width="150">
                <template slot="header">
                  <el-input
                    v-model="search"
                    size="mini"
                    placeholder="Busacar por fecha"
                  />
                </template>
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="success"
                    icon="el-icon-check"
                    @click="handleSelected(scope.$index, scope.row)"
                    >Seleccionar</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import {
  getDetailDocumentRefund,
  getDocumentsInvoice,
  getDocumentClient,
} from "@/api/document";
import { getAll as branchs } from "@/api/branch";
import { getUser } from "@/utils/auth";
export default {
  name: "step2",
  props: ["data"],
  computed: {
    ...mapGetters(["refund_data", "refund_type", "return_money"]),
  },
  watch: {
    form: {
      deep: true,
      handler(val, oldVal) {
        this.multipleSelection = this.refund_data
          ? this.refund_data.detail
          : [];
        this.setDataDocument();
      },
    },
  },
  mounted() {
    if (this.refund_data !== null && this.refund_data !== "") {
      this.loading_card = true;
      this.form.invoice = this.refund_data.invoice;
      this.form.nit = this.refund_data.nit;
      this.form.branch = this.refund_data.branch;
      this.form.observation = this.refund_data.observation;
      this.form.payment = this.refund_data.return_money_selected;
      this.searchInvoiceByConsecutivo(true);
    }
    this.form.return_money = this.return_money;
    this.getBranchs();
    this.$nextTick(() => this.$refs.document.focus());
  },
  data() {
    return {
      loading_search: false,
      loading_card: false,
      show_invoice: false,
      show_invoice_detail: false,
      user: getUser(),
      multipleSelection: [],
      tableDetail: [],
      tableInvoice: [],
      branchs: [],
      payments: [],
      search: "",
      form: {
        branch: "",
        invoice: "",
        nit: "",
        observation: "",
        return_money: false,
        payment: "",
      },
      document: {},
      rules: {},
    };
  },
  methods: {
    setPayments(payments) {
      let me = this;
      this.payments = [];
      payments.forEach((element) => {
        this.payments.push(element.payment);
      });
      // selecciono uno si solo hay una forma de pago
      setTimeout(() => {
        if (me.payments.length == 1) {
          me.form.payment = this.payments[0].id;
        }
      }, 1000);
    },
    resetValues() {
      this.loading_card = true;
      setTimeout(() => {
        this.form.branch = "";
        this.multipleSelection = [];
        this.tableDetail = [];
        this.tableInvoice = [];
        (this.show_invoice = true),
          (this.show_invoice_detail = false),
          (this.loading_card = false);
      }, 300);
    },
    searchInvoices(reset) {
      this.loading_search = true;
      this.loading_card = true;
      if (
        this.form.invoice != "" &&
        this.form.nit != "" &&
        this.form.branch != ""
      ) {
        this.searchInvoiceByConsecutivo();
      } else {
        if (this.form.nit != "") {
          this.searchDocumentsInvoices();
        } else {
          this.$message.error(
            "Por favor, ingresa un número de documento de cliente"
          );
          this.$nextTick(() => this.$refs.document.focus());
          this.loading_card = false;
          this.loading_search = false;
        }
      }
    },
    searchInvoiceByConsecutivo(selection) {
      getDetailDocumentRefund(this.form.invoice, this.form.branch)
        .then(({ data }) => {
          if (data.code === 200) {
            this.loading_card = false;
            this.show_invoice_detail = true;
            this.show_invoice = false;
            this.loading_search = false;
            this.document = data.document;
            // llenar formas de pago
            this.setPayments(data.document.payment_detail);
            this.setDetail(data.detail).then((resp) => {
              this.tableDetail = resp;
              if (selection) {
                // SELECIONAR FILAS
                this.toggleSelection();
              }
            });
          } else {
            console.log(data.error);
            this.$message.error("Error: " + data.error);
            this.loading_card = false;
            this.loading_search = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
          this.loading_card = false;
          this.loading_search = false;
        });
    },
    searchDocumentsInvoices() {
      var nit = this.form.nit != "" ? this.form.nit : null;
      getDocumentsInvoice(this.refund_type.type_pivot_id, nit)
        .then(({ data }) => {
          if (data.code === 200) {
            this.loading_card = false;
            this.show_invoice_detail = false;
            this.show_invoice = true;
            this.loading_search = false;
            this.setInvoices(data.document).then((resp) => {
              this.tableInvoice = resp;
            });
          } else {
            console.log(data.error);
            this.$message.error("Error: " + data.error);
            this.loading_card = false;
            this.loading_search = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
          this.loading_card = false;
          this.loading_search = false;
        });
    },
    searchInvoiceClient() {
      getDocumentClient(this.form.nit, this.user.sucursal_id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.loading_card = false;
            this.show_invoice_detail = false;
            this.show_invoice = true;
            this.loading_search = false;
            this.setInvoices(data.document).then((resp) => {
              this.tableInvoice = resp;
            });
          } else {
            console.log(data.error);
            this.$message.error("Error: " + data.error);
            this.loading_card = false;
            this.loading_search = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
          this.loading_card = false;
          this.loading_search = false;
        });
    },
    async setDetail(data) {
      var array = [];
      await data.forEach((el) => {
        var resp = {};
        resp.id = el.id;
        resp.product_id = el.product.id;
        resp.code = el.product.codigo;
        resp.description = el.product.descripcion;
        resp.quantity = el.cantidad;
        resp.price = parseFloat(el.precio);
        resp.descuento = parseFloat(el.descuento);
        resp.ipc = parseFloat(el.ipc) * parseFloat(el.cantidad);
        resp.iva_percent = el.iva;
        resp.iva = Math.round(
          (parseFloat(el.precio) * parseFloat(el.iva)) / 100
        );
        resp.total = roundToTheNearestAnything(
          (parseFloat(el.precio) +
            parseFloat(el.ipc) * parseFloat(el.cantidad) +
            (parseFloat(el.precio) * parseFloat(el.iva)) / 100) - parseFloat(el.descuento)
        );
        array.push(resp);
      });
      return array;
    },
    async setInvoices(data) {
      var array = [];
      await data.forEach((el) => {
        var resp = {};
        resp.id = el.id;
        resp.consecutive = el.consecutivo;
        resp.date = el.fecha;
        resp.branch = el.branch;
        resp.name = el.person.nombre;
        resp.person = el.person;
        resp.value = el.id;
        resp.type = el.type;
        array.push(resp);
      });
      return array;
    },
    toggleSelection() {
      let me = this;
      var rowsNews = [];
      var dataRows = this.refund_data.detail;
      setTimeout(() => {
        dataRows.forEach((el, indx) => {
          rowsNews.push(me.tableDetail[indx]);
        });
        if (rowsNews) {
          rowsNews.forEach((row) => {
            me.$refs.multipleTable.toggleRowSelection(row, true);
          });
        } else {
          me.$refs.multipleTable.clearSelection();
        }
      }, 200);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.setDataDocument();
    },
    handleSelected(index, row) {
      if (row.consecutive != "") {
        this.form.invoice = row.consecutive;
        this.form.branch = row.branch.id;
        this.form.nit = row.person.documento;
        this.searchInvoices(true);
        this.setDataDocument();
      }
    },
    setDataDocument() {
      var data = {
        invoice: this.form.invoice,
        nit: this.form.nit,
        branch: this.form.branch,
        observation: this.form.observation,
        detail: this.multipleSelection,
        document: this.document,
        return_money: this.return_money,
        return_money_selected: this.form.payment,
      };
      this.$store.commit("SET_REFUND_DATA", data);
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    roundToTheNearestAnything(val) {
      return roundToTheNearestAnything(val);
    },
    getBranchs() {
      branchs()
        .then(({ data }) => {
          this.branchs = data;
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
          this.loading_card = false;
          this.loading_search = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
}
.box-card {
  height: 320px;
}
.btn-search {
  text-align: center;
}
.el-card__header {
  padding: 10px 20px !important;
}
.observation {
  margin-top: 10px;
}
@media (max-width: 1000px) {
  .tables-load {
    margin-bottom: 20px;
  }
}
// 1130615579
</style>