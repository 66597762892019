import request from '@/utils/request'

export function saveLoadInventory(data) {
  return request({
    url: 'document/saveLoadInventory',
    method: 'post',
    data
  })
}

export function loadInventory(data) {
  return request({
    url: 'document/loadInventory',
    method: 'post',
    data
  })
}

export function getLoadInventoryAll(pageSize, currentPage, typeId) {
  return request({
    url: 'document/getLoadInventoryAll/' + pageSize + '/' + typeId + '?page=' + currentPage,
    method: 'get'
  });
}

export function loadInventoryDelete(id) {
  return request({
    url: 'document/loadInventoryDelete/' + id,
    method: 'delete'
  });
}

export function getDetailDocumentRefund(consecutive, branch_id) {
  return request({
    url: 'document/getDetailDocumentRefund/' + consecutive + '/' + branch_id,
    method: 'get'
  });
}

export function getDocumentsInvoice(type, nit) {
  return request({
    url: 'document/getDocumentsInvoice/' + type + '/' + nit,
    method: 'get'
  });
}

export function getDocumentClient(nit, branch_id) {
  return request({
    url: 'document/getDocumentClient/' + nit + '/' + branch_id,
    method: 'get'
  });
}

export function saveRefund(data) {
  return request({
    url: 'document/saveRefund',
    method: 'post',
    data
  });
}

export function getDocuments(page_size, currentPage, types_documents, consecutive, date, client, documentConsecutive) {
  return request({
    url: 'document/getDocuments/' + page_size + '/' + types_documents + '/' + consecutive +
      '/' + date + '/' + client+'/' + documentConsecutive + '?page=' + currentPage,
    method: 'get',
  });
}

export function anulateDocument(data) {
  return request({
    url: 'document/anulateDocument',
    method: 'post',
    data
  });
}

export function getDocumentTransfer(data) {
  return request({
    url: 'document/getDocumentTransfer',
    method: 'post',
    data
  });
}

export function acceptTransfer(data) {
  return request({
    url: 'document/acceptTransfer',
    method: 'post',
    data
  });
}

export function printTransfer(data) {
  return request({
    url: 'document/printTransfer',
    method: 'post',
    data
  });
}

export function getInventory(pageSize, currentPage, productId, categoryId, typeInventory) {
  return request({
    url: 'document/getInventory/' + pageSize + '/' + productId + '/' + categoryId + '/' +
      typeInventory + '?page=' + currentPage,
    method: 'get'
  });
}

export function dowloadInventory(data) {
  return request({
    url: 'document/dowloadInventory',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

export function saveExpenses(data) {
  return request({
    url: 'document/saveExpenses',
    method: 'post',
    data,
  });
}

export function getExpenses(page_size, currentPage, types_document) {
  return request({
    url: 'document/getDocuments/' + page_size + '/' + types_document + '?page=' + currentPage,
    method: 'get',
  });
}

export function deleteExpenses(id) {
  return request({
    url: 'document/deleteExpenses/' + id,
    method: 'delete'
  });
}

export function getDetailByDocumentId(page_size, currentPage, documentId, transaction) {
  return request({
    url: 'document/getDetailByDocumentId/' + page_size + '/' + documentId + '/' + transaction + '?page=' + currentPage,
    method: 'get',
  });
}

export function deleteDetailById(id) {
  return request({
    url: 'document/deleteDetailById/' + id,
    method: 'get',
  });
}

export function getCheckBox(branch, dateSelected) {
  return request({
    url: 'document/getCheckBox/' + branch + '/' + dateSelected,
    method: 'get',
  });
}


