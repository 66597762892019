import axios from 'axios'
import { getToken } from '@/utils/auth'

// Creación de la instancia de Axios
const service = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API, // api base_url
  timeout: 50000, // Tiempo máximo de espera para una solicitud ajax
  headers: {
    'Authorization': 'Bearer ' + getToken(),
    'Content-Type': 'application/json',
    // 'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true // Habilitar el envío de cookies en las solicitudes
})

service.interceptors.request.use(async (config) => {
  if (config.url === '/auth/login') {
    // Espera la petición a csrf-cookie antes de continuar con la solicitud de login
    await service.get('/sanctum/csrf-cookie', { baseURL: process.env.VUE_APP_ROOT });
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

service.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Redirige al login solo si la respuesta es 401
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default service
