<template>
  <div class="scrollable only-y checkBox">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30" style="background-color: #e1e1e1">
      <el-row>
        <el-col>
          <el-form :model="form" ref="form" size="medium" class="form" :inline="true">
            <el-form-item prop="branchId">
              <el-select
                v-model="form.branchId"
                filterable
                placeholder="Selecciona una tienda"
                @clear="form.branchId = ''"
                clearable
                disabled
              >
                <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.razon_social"
                  :value="item.id"
                >
                  <span style="float: left"
                    ><i class="mdi mdi-store"></i> {{ item.razon_social }}</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="dateSelected">
              <el-date-picker
                v-model="form.dateSelected"
                type="date"
                placeholder="Fecha"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="getCheckBox()"
                :loading="loading"
                icon="el-icon-search"
                >Buscar</el-button
              >
              <el-button
                type="success"
                @click="save()"
                :loading="loadingSave"
                icon="el-icon-check"
                :disabled="checkBox.cuadre !== null || loadingInfo === true"
                >Guardar</el-button
              >
              <el-button
                @click="startPrint()"
                :loading="loadingPrint"
                icon="el-icon-printer"
                :disabled="checkBox.cuadre === null && !loadingInfo"
                >Imprimir</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <!-- datos -->
      <el-row :gutter="50">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-card
            shadow="never"
            v-loading="loadingInfo"
            element-loading-text="Cargando información..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgb(246 246 246 / 80%)"
          >
            <div class="widget p-10">
              <div class="text-uppercase text-right flex">
                <div class="icon-box ph-15 accent-text">
                  <i class="mdi mdi-file-document-outline"></i>
                </div>
                <div class="box grow">
                  <h3 class="m-0">{{ checkBox.cuadre }}</h3>
                  <p class="m-0">Cuadre N°</p>
                </div>
              </div>
            </div>
            <div class="widget p-10">
              <div class="text-uppercase text-right flex">
                <div class="icon-box ph-15 accent-text"><i class="mdi mdi-currency-usd"></i></div>
                <div class="box grow">
                  <h3 class="m-0">{{ formatNumber(checkBox.valor_caja) }}</h3>
                  <p class="m-0">Base de caja</p>
                </div>
              </div>
            </div>
            <div class="widget p-10">
              <div class="text-uppercase text-right flex">
                <div class="icon-box ph-15 accent-text">
                  <i class="mdi mdi-calendar-month-outline"></i>
                </div>
                <div class="box grow">
                  <h3 class="m-0">{{ checkBox.fecha }}</h3>
                  <p class="m-0">Fecha</p>
                </div>
              </div>
            </div>
          </el-card>
          <el-card
            shadow="never"
            class="mt-20"
            v-loading="loadingInfo"
            element-loading-text="Cargando información..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgb(246 246 246 / 80%)"
          >
            <div class="widget">
              <div class="text-uppercase text-right">
                <h2 class="mv-0 total">
                  <span class="txt-total">TOTAL:</span> <br />$
                  {{
                    formatNumber(
                      formatNumber(
                        totalIncome - totalExpenses * -1 + totalAmortizations,
                        50,
                        true
                      )
                    )
                  }}
                </h2>
                <h5 class="mv-0 income-expenses">Ingresos menos Egresos</h5>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="18" :lg="18" :xl="18">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card
                shadow="never"
                class="data-check-box income-card"
                v-loading="loadingInfo"
                element-loading-text="Cargando información..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(246 246 246 / 80%)"
              >
                <h3><i class="mdi mdi-trending-up"></i> Ingresos</h3>
                <el-table
                  :data="income"
                  style="width: 100%"
                  size="medium"
                  show-summary
                  :summary-method="getSummariesIncome"
                >
                  <el-table-column prop="descripcion" label="Descripción"> </el-table-column>
                  <el-table-column prop="valor" label="Valor" width="180" align="right">
                    <template slot-scope="scope">
                      <div>$ {{ formatNumber(scope.row.valor) }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card
                shadow="never"
                class="data-check-box expenses-card"
                v-loading="loadingInfo"
                element-loading-text="Cargando información..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(246 246 246 / 80%)"
              >
                <h3><i class="mdi mdi-trending-down"></i> Egresos</h3>
                <el-table
                  :data="expenses"
                  style="width: 100%"
                  size="medium"
                  show-summary
                  :summary-method="getSummariesExpenses"
                >
                  <el-table-column prop="descripcion" label="Descripción"> </el-table-column>
                  <el-table-column prop="valor" label="Valor" width="180" align="right">
                    <template slot-scope="scope">
                      <div>$ {{ formatNumber(scope.row.valor) }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card
                shadow="never"
                class="data-check-box returns-card"
                v-loading="loadingInfo"
                element-loading-text="Cargando información..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(246 246 246 / 80%)"
              >
                <h3><i class="mdi mdi-keyboard-return"></i> Devoluciones</h3>
                <el-table
                  :data="returns"
                  style="width: 100%"
                  size="medium"
                  show-summary
                  :summary-method="getSummariesReturns"
                >
                  <el-table-column prop="name" label="Descripción"> </el-table-column>
                  <el-table-column prop="value" label="Valor" width="180" align="right">
                    <template slot-scope="scope">
                      <div>$ {{ formatNumber(formatNumber(scope.row.value, 50, true)) }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card
                shadow="never"
                class="data-check-box amortization-card"
                v-loading="loadingInfo"
                element-loading-text="Cargando información..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(246 246 246 / 80%)"
              >
                <h3><i class="mdi mdi-account-cash-outline"></i> Pago de créditos</h3>
                <el-table
                  :data="amortizations"
                  style="width: 100%"
                  size="medium"
                  show-summary
                  :summary-method="getSummariesAmortizations"
                >
                  <el-table-column prop="name" label="Descripción">
                    <template slot-scope="scope">
                      <div>{{ scope.row.name }}</div>
                      <small>#Factura: {{ scope.row.invoice }}</small>
                    </template>
                  </el-table-column>
                  <el-table-column prop="value" label="Valor" width="180" align="right">
                    <template slot-scope="scope">
                      <div>$ {{ formatNumber(formatNumber(scope.row.value, 50, true)) }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card
                shadow="never"
                class="data-check-box anulations-card"
                v-loading="loadingInfo"
                element-loading-text="Cargando información..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgb(246 246 246 / 80%)"
              >
                <h3><i class="mdi mdi-file-cancel-outline"></i> Anulaciones</h3>
                <el-table
                  :data="canceled"
                  style="width: 100%"
                  size="medium"
                  show-summary
                  :summary-method="getSummariesCanceled"
                >
                  <el-table-column prop="name" label="# Factura"> </el-table-column>
                  <el-table-column prop="value" label="Valor" width="180" align="right">
                    <template slot-scope="scope">
                      <div>$ {{ formatNumber(formatNumber(scope.row.value, 50, true)) }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
import { mapGetters } from "vuex";
import * as qz from "qz-tray";
import { roundToTheNearestAnything, formatNumber } from "@/utils/number";
import { getAll, getBaseCash, saveCheckBox } from "@/api/store";
import { getCheckBox as getCheckBoxAll } from "@/api/document";
import { getUser, getDataPos } from "@/utils/auth";
import { actualDate } from "@/utils/date";
export default {
  name: "checkBox",
  mounted() {
    let me = this;
    this.getAllStores();
    setTimeout(() => {
      me.getCheckBox();
    }, 1000);
  },
  created() {
    let me = this;
    me.startConnectionPrint();
  },
  computed: {
    ...mapGetters(["printer_default"]),
    return_pay: function () {
      return this.total_pay - this.total;
    },
  },
  data() {
    return {
      pathImagesUrl: process.env.VUE_APP_ROOT,
      urlImage: process.env.VUE_APP_ROOT + "static/images/uploads/",
      user: getUser(),
      dataPos: getDataPos(),
      loading: false,
      loadingInfo: false,
      loadingSave: false,
      loadingPrint: false,
      form: {
        branchId: getUser().branch.id,
        dateSelected: actualDate().slice(0, -9),
      },
      branches: [],
      income: [],
      expenses: [],
      returns: [],
      amortizations: [],
      canceled: [],
      checkBox: {
        cuadre: null,
        valor_caja: 0,
        fecha: "",
      },
      totalIncome: 0,
      totalExpenses: 0,
      totalReturns: 0,
      totalAmortizations: 0,
      totalCanceled: 0,
      company: {},
      contact_print: "",
      incomeTable: "",
      expensesTable: "",
      amortizationsTable: "",
      returnsTable: "",
      returnsCanceled: "",
    };
  },
  methods: {
    startPrint() {
      let me = this;
      if (me.printer_default !== "") {
        if (!qz.websocket.isActive()) {
          me.startConnectionPrint();
        }
        setTimeout(() => {
          me.sendPrinter(me.printer_default);
        }, 300);
      } else {
        if (qz.websocket.isActive()) {
          qz.printers
            .getDefault()
            .then(function (data) {
              if (!data) {
                this.$swal.fire({
                  icon: "warning",
                  title: "Atención!",
                  text: "No hay una impresora predeterminada!",
                });
              } else {
                me.$store.commit("SET_PRINTER", data);
                me.startPrint();
              }
            })
            .catch(function (error) {
              console.log("error: ", error);
            });
        }
      }
    },
    sendPrinter(printer) {
      let me = this;
      var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
      var document_text = "CUADRE DE CAJA";
      var documentHead =
        '<div style="text-align:center;">' +
        '<img style="width:150px" src="' +
        this.pathImagesUrl +
        'static/images/logo.png" /> </br>' +
        this.company.razon_social +
        "</div>" +
        '<div style="text-align:center;">NIT: ' +
        this.company.nit +
        "</div>" +
        '<div style="text-align:center;">Caja: ' +
        this.dataPos.computer.name +
        "</div>" +
        '<div style="margin: 10px 0 10px 0;"></div>' +
        '<div style="text-align:center;">' +
        this.user.branch.razon_social +
        "</div>" +
        '<div style="text-align:center;">' +
        this.user.branch.direccion +
        "</div>" +
        '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
        // invoice
        '<div style="text-align:center;">' +
        document_text +
        " #" +
        this.checkBox.cuadre +
        "</div>" +
        '<div style="text-align:center;">' +
        actualDate(this.checkBox.created_at) +
        "</div>";

      var documentFooter =
        '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
        '<div style="font-size:10px;text-align:center;margin-top:20px;">' +
        this.contact_print +
        "</div>" +
        '<div style="font-size:15px;text-align:center;margin-top:20px;font-weight: 900;">**** CUADRE DE CAJA ****</div>' +
        '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';

      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            "<html>" +
            '<body style="font-size:11px;">' +
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // client
            '<div style="padding-left:10px;font-size:15px;">Responsable: ' +
            this.checkBox.name +
            "</div>" +
            '<div style="padding-left:10px;font-size:15px;">Base de caja: ' +
            formatNumber(parseFloat(this.checkBox.valor_caja)) +
            "</div>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // table income
            '<div style="padding-left:10px;margin-bottom: 10px;font-size:15px;font-weight: bold;">Ingresos</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.incomeTable +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // table income
            '<div style="padding-left:10px;margin-bottom: 10px;font-size:15px;font-weight: bold;">Egresos</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.expensesTable +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // table income
            '<div style="padding-left:10px;margin-bottom: 10px;font-size:15px;font-weight: bold;">Pago de créditos</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.amortizationsTable +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:15px;">' +
            '<tr><td style="padding-left:10px;">TOTAL</td>' +
            '<td style="text-align:right;padding-right:10px">' +
            "$ " +
            formatNumber(
              parseFloat(me.totalIncome + me.totalAmortizations - me.totalExpenses * -1)
            ) +
            "</td></tr>" +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 15px 0 5px 0;"></div>' +
            // table returns
            '<div style="padding-left:10px;margin-bottom: 10px;font-size:15px;font-weight: bold;">Devoluciones</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.returnsTable +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 15px 0 5px 0;"></div>' +
            // table canceled
            '<div style="padding-left:10px;margin-bottom: 10px;font-size:15px;font-weight: bold;">Anulaciones</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.canceledTable +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            documentFooter +
            '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
            "</div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    save() {
      this.loadingSave = true;
      this.$confirm("Confirmas que los datos del cuadre son correctos?", "Atención!", {
        confirmButtonText: "Si, guardar cuadre",
        cancelButtonText: "No, cancelar",
        type: "warning",
      })
        .then(() => {
          this.saveCheckBox();
        })
        .catch(() => {
          this.loadingSave = false;
          this.$message({
            type: "info",
            message: "Operación cancelada",
          });
        });
    },
    saveCheckBox() {
      let me = this;
      saveCheckBox({
        id: me.checkBox.id,
        branchId: me.form.branchId,
        income: me.income,
        expenses: me.expenses,
        returns: me.returns,
        amortizations: me.amortizations,
        canceled: me.canceled,
      })
        .then(({ data }) => {
          console.log("Save checkbox", data);
          this.$message({
            type: "success",
            message: "Cuadre guardado exitosamente",
          });
          me.loadingSave = false;
          me.getCheckBox();
        })
        .catch(function (error) {
          console.log(error);
          me.loadingSave = false;
          me.$message.error("Oops", "Error al guardar cuadre.");
        });
    },
    getCheckBoxAll() {
      let me = this;
      me.loading = true;
      me.loadingInfo = true;
      getCheckBoxAll(me.form.branchId, me.form.dateSelected)
        .then(({ data }) => {
          me.company = data.company;
          me.income = data.data.income;
          me.expenses = data.data.expenses;
          me.returns = data.data.returns;
          me.amortizations = data.data.amortizations;
          me.canceled = data.data.canceled;
          me.loading = false;
          me.loadingInfo = false;
          me.incomeTable = "";
          console.log();

          setTimeout(() => {
            if (me.income.length > 0) {
              me.income.forEach(function (el) {
                me.incomeTable +=
                  '<tr><td style="padding-left:10px;">' +
                  el.descripcion +
                  '</td><td style="text-align:right;padding-right:10px">' +
                  "$ " +
                  formatNumber(parseFloat(el.valor)) +
                  "</td></tr>";
              });
            }
            me.expensesTable = "";
            if (me.expenses.length > 0) {
              me.expenses.forEach(function (el) {
                me.expensesTable +=
                  '<tr><td style="padding-left:10px;">' +
                  el.descripcion +
                  '</td><td style="text-align:right;padding-right:10px">' +
                  "$ " +
                  formatNumber(parseFloat(el.valor)) +
                  "</td></tr>";
              });
            }
            me.amortizationsTable = "";
            if (me.amortizations.length > 0) {
              me.amortizations.forEach(function (el) {
                me.amortizationsTable +=
                  '<tr><td style="padding-left:10px;">' +
                  el.name +
                  "<br> #Factura: " +
                  el.invoice +
                  '</td><td style="text-align:right;padding-right:10px">' +
                  "$ " +
                  formatNumber(parseFloat(el.value)) +
                  "</td></tr>";
              });
            }
            me.returnsTable = "";
            if (me.returns.length > 0) {
              me.returns.forEach(function (el) {
                me.returnsTable +=
                  '<tr><td style="padding-left:10px;">' +
                  el.name +
                  '</td><td style="text-align:right;padding-right:10px">' +
                  "$ " +
                  formatNumber(parseFloat(el.value)) +
                  "</td></tr>";
              });
            }
            me.canceledTable = "";
            if (me.canceled.length > 0) {
              me.canceled.forEach(function (el) {
                me.canceledTable +=
                  '<tr><td style="padding-left:10px;">#Factura: ' +
                  el.name +
                  '</td><td style="text-align:right;padding-right:10px">' +
                  "$ " +
                  formatNumber(parseFloat(el.value)) +
                  "</td></tr>";
              });
            }
          }, 200);
        })
        .catch(function (error) {
          me.loading = false;
          me.loadingInfo = false;
          me.$message.error("Oops", "Error al traer datos del cuadre.");
          console.log(error);
        });
    },
    getAllStores() {
      let me = this;
      getAll()
        .then(({ data }) => {
          me.branches = data;
        })
        .catch(function (error) {
          console.log(error);
          me.$message.error("Oops", "Error al traer las tiendas.");
        });
    },
    getCheckBox() {
      let me = this;
      me.loading = true;
      me.loadingInfo = true;
      me.resetValuesPrint();
      getBaseCash(me.form.branchId, me.form.dateSelected)
        .then(({ data }) => {
          if (data.length === 0) {
            me.checkBox = {
              id: 897,
              razon_social: "YAMAHMOTOS",
              name: "Gerencia",
              cuadre: null,
              valor_caja: 0,
              fecha: me.checkBox.fecha,
              observacion: null,
              fecha_inicio: null,
              fecha_final: null,
              impreso: 0,
            };
            me.loading = false;
            me.loadingInfo = false;
            me.$message({
              type: "warning",
              message: "El cuadre de caja no ha sido creado para esta fecha.",
            });
            return;
          }else{
            me.checkBox = data[0];
          }
          me.loading = false;
          me.loadingInfo = false;
          me.getCheckBoxAll();
        })
        .catch(function (error) {
          me.loading = false;
          me.loadingInfo = false;
          me.$message.error("Oops", "Error al traer la venta diaria.");
          console.log(error);
        });
    },
    formatNumber(number, round, aprox) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      if (typeof aprox != "undefined") {
        // APROXIMO AL ENTERO MAS CERCANO
        num = roundToTheNearestAnything(number);
      }
      return num;
    },
    getSummariesIncome(param) {
      let me = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Ingresos totales";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = "$ " + this.formatNumber(sum);
          me.totalIncome = sum;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    getSummariesExpenses(param) {
      let me = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Egresos totales";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = "$ " + this.formatNumber(sum);
          me.totalExpenses = sum;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    getSummariesReturns(param) {
      let me = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Devoluciones totales";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = "$ " + this.formatNumber(this.formatNumber(sum, 50, true));
          me.totalReturns = sum;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    getSummariesAmortizations(param) {
      let me = this;
      me.totalAmortizations = 0;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Pagos totales";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = "$ " + this.formatNumber(this.formatNumber(sum, 50, true));
          me.totalAmortizations = sum;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    getSummariesCanceled(param) {
      let me = this;
      me.totalCanceled = 0;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Anulaciones totales";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          let sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = "$ " + this.formatNumber(this.formatNumber(sum, 50, true));
          me.totalCanceleds = sum;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    startConnectionPrint() {
      let me = this;
      setTimeout(() => {
        // Authentication setup ///
        var privateKey =
          "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
          "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
          "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
          "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
          "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
          "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
          "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
          "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
          "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
          "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
          "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
          "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
          "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
          "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
          "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
          "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
          "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
          "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
          "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
          "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
          "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
          "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
          "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
          "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
          "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
          "spuV+871lajMrXi3/faa/w==\n" +
          "-----END PRIVATE KEY-----";

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            try {
              var pk = KEYUTIL.getKey(privateKey);
              var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              var hex = sig.sign();
              // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
          resolve(
            axios
              .get(process.env.VUE_APP_ROOT_API + "/certs/getQzCertificate")
              .then((response) => response.data)
          );
        });
        if (!qz.websocket.isActive()) {
          qz.websocket.connect().then(function () {
            console.log("Connected QZ-tray! ", qz.websocket.isActive());
            me.$store.commit("SET_PRINTER_CONNECT", true);
            me.$store.commit("SET_PRINTER_RECONNECT", false);
            qz.printers
              .getDefault()
              .then(function (data) {
                me.$store.commit("SET_PRINTER", data);
              })
              .catch(function (error) {
                console.log("error: ", error);
              });
          });
        } else {
          me.$message({
            message: "La conección con la impresora, ya ha sido establecida.",
            type: "success",
          });
          me.$store.commit("SET_PRINTER_CONNECT", true);
          me.$store.commit("SET_PRINTER_RECONNECT", false);
        }
      }, 2000);
    },
    resetValuesPrint() {
      this.incomeTable = "";
      this.expensesTable = "";
      this.amortizationsTable = "";
      this.returnsTable = "";
      this.canceledTable = "";
      this.totalIncome = 0;
      this.totalExpenses = 0;
      this.totalReturns = 0;
      this.totalAmortizations = 0;
      this.totalCanceled = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkBox .widget .icon-box {
  font-size: 30px;
}

.data-check-box {
  margin-bottom: 20px;

  h3 {
    margin-top: 0px;
  }
}

.amortization-card {
  box-shadow: 0 2px 2px 2px #ffb10042;
}

.income-card {
  box-shadow: 0 2px 2px 2px #33ff0042;
}

.expenses-card {
  box-shadow: 0 2px 2px 2px #ff110042;
}

.returns-card {
  box-shadow: 0 2px 2px 2px #0051ff42;
}

.anulations-card {
  box-shadow: 0 2px 2px 2px #d800ff42;
}

.income-expenses {
  color: #adadad;
}

.total {
  font-size: 30px;
  font-weight: 900;
  color: #029d02;
}

.txt-total {
  color: #424242;
}
</style>

<style lang="scss">
.el-table__footer-wrapper {
  font-weight: 900;
}
</style>
